<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.download") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="container">
        <div class="item">
          <!-- <vue-qr
            v-if="sysDataDictionaryWbObj?.APPXZEWM?.dataContent"
            :text="sysDataDictionaryWbObj?.APPXZEWM?.dataContent"
            qid="download_A"
          ></vue-qr> -->
          <vue-qr
            v-if="sysDataDictionaryWbObj?.APPXZLJ?.dataContent"
            :text="sysDataDictionaryWbObj?.APPXZLJ?.dataContent"
            qid="download_A"
          ></vue-qr>
          
        </div>
        <div class="item">
          <div class="loadButton" @click="download">
            <span>Download APP</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: {
    vueQr,
  },
  setup() {
    const { t } = useI18n();
    const download = () => {
      var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        if (sysDataDictionaryWbObj?.value?.APPXZLJ?.dataContent) {
          location.href = sysDataDictionaryWbObj?.value?.APPXZLJ?.dataContent;
        }
      } else {
        if (
          sysDataDictionaryWbObj?.value?.TXHQDZ?.dataContent &&
          sysDataDictionaryWbObj?.value?.AZAPKPZ?.dataContent
        ) {
          location.href =
            sysDataDictionaryWbObj?.value?.TXHQDZ?.dataContent +
            sysDataDictionaryWbObj?.value?.AZAPKPZ?.dataContent;
        }
      }
    };
    return {
      t,
      sysDataDictionaryWbObj,
      download,
    };
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 33% !important;
  margin-left: 33.5%;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .item {
    margin: 15px 0px;
    .loadButton {
      height: 50px;
      width: 200px;
      border-radius: 50px;
      background: #e23d6d;
      line-height: 50px;
      text-align: center;
      box-sizing: border-box;
      padding: 0px 15px;
      margin: 10px 0px;
      font-size: 18px;
      color: #fff;
      i {
        font-size: 20px;
      }
    }
  }
}
</style>